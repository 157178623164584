
import { Component, Vue } from "vue-property-decorator";
import RankingMedals from "./ranking/medals.vue";
@Component({
  components: { RankingMedals },
})
export default class Home extends Vue {
  private readonly ranking = require("@/assets/js/ranking");
  linkToHistory() {
    this.$router.push("/history");
  }
}
