
import { Component, Vue, Watch } from "vue-property-decorator";
@Component({
  props: {
    paths: {
      type: Array,
      required: true,
    },
  },
})
export default class Lightbox extends Vue {
  private readonly paths!: Array<string>;
  private activeImageIndex: number = -1;

  private next() {
    this.activeImageIndex = Math.min(
      this.activeImageIndex + 1,
      this.paths.length - 1
    );
  }
  private previous() {
    this.activeImageIndex = Math.max(this.activeImageIndex - 1, 0);
  }

  get activeImage(): boolean {
    return this.activeImageIndex >= 0;
  }

  @Watch("paths")
  onPathsChange() {
    this.resetActiveImageIndex();
  }

  showImageIndex(_index: number) {
    this.activeImageIndex = _index;
  }

  onKeyUp(e: KeyboardEvent) {
    console.log(e);
  }

  resetActiveImageIndex() {
    console.log("resetActiveImageIndex");
    this.activeImageIndex = -1;
  }
}
