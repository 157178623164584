
import { Component, Vue } from "vue-property-decorator";
import Lightbox from "@/components/_lib/lightbox.vue";

@Component({
  components: { Lightbox },
})
export default class Images extends Vue {
  private activeImageSet: string | null = null;
  private sets: Array<string> = ["18.1", "21.1"];

  setImageSet(name: string) {
    this.activeImageSet = name;
  }

  get imagePathsOfActiveImageSet(): Array<string> {
    switch (this.activeImageSet) {
      case "18.1":
        return [
          require("@/assets/images/history/18-1/A.png"),
          require("@/assets/images/history/18-1/B.png"),
          require("@/assets/images/history/18-1/C.png"),
        ];
      case "21.1":
        return [
          require("@/assets/images/history/21-1/D.png"),
          require("@/assets/images/history/21-1/E.png"),
        ];
      default:
        return [];
    }
  }
}
