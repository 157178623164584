
import { Component, Vue } from "vue-property-decorator";
import TournamentFfa from "@/components/tournament/ffa.vue";
import TournamentFinal from "@/components/tournament/final.vue";
import TournamentComplete from "@/components/tournament/complete.vue";
@Component({
  components: { TournamentFfa, TournamentFinal, TournamentComplete },
})
export default class Tournament extends Vue {
  private config = {
    minimumPlayers: 8,
  };

  get players() {
    return this.$store.state.data.players;
  }

  get tournamentState() {
    return this.$store.state.active;
  }

  launchTournament() {
    if (this.players < this.config.minimumPlayers) {
      alert(
        "Turnier kann nicht gestartet werden. Es werden mindestens 8 Spieler benötigt (Go to 'Setup')."
      );
    } else {
      this.$store.commit("setTournamentState", "ffa");
    }
  }
}
