
import { Component, Vue } from "vue-property-decorator";
import { RankingEntry } from "@/assets/js/ranking";
import RankingResults from "@/components/ranking/results.vue";
import RankingMedals from "@/components/ranking/medals.vue";

@Component({
  components: { RankingResults, RankingMedals },
})
export default class Ranking extends Vue {
  private readonly ranking = require("@/assets/js/ranking");
  private readonly members = require("@/assets/js/members");
  private active: string | null = null;

  readonly sets: Array<{
    key: string;
    title: string;
  }> = [
    { key: "results", title: "Resultate" },
    { key: "totalIndividual", title: "Medaillenspiegel (Individuell)" },
    { key: "totalRegion", title: "Medaillenspiegel (Region)" },
  ];

  show(type: string) {
    this.active = type;
  }

  get results() {
    return this.ranking.results;
  }
}
