import * as Phaser from "phaser";
import Sprite = Phaser.GameObjects.Sprite;

class Demo extends Phaser.Scene {
  static width: number = 1024;
  static height: number = 768;
  static backgroundColor: string = "#FC0FC0";

  private readonly spacing = 8;
  private readonly spriteResolution = 32;
  private readonly gridsize = 20;
  private readonly robots: Array<Sprite> = [];

  private previewRobot!: Phaser.GameObjects.Sprite;
  private previewLane!: Phaser.GameObjects.Rectangle;
  private isPreviewVisible: boolean = true;

  stones = require("../../images/game/bgs/stones.png");
  robotA = require("../../images/game/sprites/robot-a.png");

  constructor() {
    super("demo");
  }

  loadImage(id: string, img: string) {
    return img.split(":")[0] == "data"
      ? this.textures.addBase64(id, img)
      : this.load.image(id, img);
  }

  preload() {
    this.loadImage("stones", this.stones);
    this.loadImage("robot-a", this.robotA);
  }

  create() {
    this.addBackground();
    this.initObjects();
    this.registerPointerEvents();
  }
  addBackground() {
    this.add.image(0, 0, "stones").setOrigin(0);
  }
  initObjects() {
    this.previewRobot = this.add
      .sprite(0, 0, "robot-a")
      .setOrigin(0)
      .setAlpha(0);
    this.previewLane = this.add
      .rectangle(0, 0, 32, Demo.height, 0x000000)
      .setOrigin(0, 0);
    this.previewLane.setAlpha(0);
  }
  registerPointerEvents() {
    this.input.on("pointermove", (pointer: Phaser.Input.Pointer) => {
      if (this.isPreviewVisible) {
        this.updatePreviewRobot(this.getSpaceIndexOfPointerX(pointer.x));
      }
    });
    this.input.on("pointerdown", (pointer: Phaser.Input.Pointer) => {
      if (this.isPreviewVisible) {
        this.instantiateRobot(this.getSpaceIndexOfPointerX(pointer.x));
      }
    });
  }

  getSpaceIndexOfPointerX(x: number): number {
    if (x <= this.getGridPadding() || x > Demo.width - this.getGridPadding()) {
      return -1;
    }
    const xOnGrid: number = x - this.getGridPadding();

    if (xOnGrid % (this.spriteResolution + 8) > this.spriteResolution) {
      return -1;
    }

    const spaceIndex = Math.floor(xOnGrid / (this.spriteResolution + 8));

    return spaceIndex;
  }

  getGridPadding(): number {
    return (Demo.width - this.getGridSpaceToFill()) / 2;
  }
  getGridSize() {
    return Demo.width - this.getGridPadding() * 2;
  }

  getGridSpaceToFill(): number {
    return (
      this.spriteResolution * this.gridsize + (this.gridsize - 1) * this.spacing
    );
  }

  getPositionOfSpaceIndex(spaceIndex: number): { x: number; y: number } {
    const xPos: number =
      this.getGridPadding() + spaceIndex * 32 + spaceIndex * this.spacing;
    const yPos: number = Demo.height - (32 + 16); // Bottom of the screen
    return { x: xPos, y: yPos };
  }

  instantiateRobot(spaceIndex: number) {
    if (spaceIndex < 0) {
      return;
    }
    const pos = this.getPositionOfSpaceIndex(spaceIndex);
    this.robots.push(
      this.add.sprite(0, 0, "robot-a").setOrigin(0).setPosition(pos.x, pos.y)
    );
  }
  updatePreviewRobot(spaceIndex: number) {
    if (spaceIndex < 0) {
      this.previewRobot.setAlpha(0);
      console.log(this.previewLane.fillAlpha);
      this.previewLane.setAlpha(0);
      console.log(this.previewLane.fillAlpha);
    } else {
      const pos = this.getPositionOfSpaceIndex(spaceIndex);
      this.previewRobot.setPosition(pos.x, pos.y);
      this.previewLane.setPosition(pos.x, 0); // Center the lane on the previewRobot
      this.previewRobot.setAlpha(0.75);
      this.previewLane.setAlpha(0.1);
    }
  }
}

const config = {
  type: Phaser.AUTO,
  backgroundColor: Demo.backgroundColor,
  width: Demo.width,
  height: Demo.height,
  scene: Demo,
};

function createGame(element_id: string) {
  return new Phaser.Game({
    type: Phaser.AUTO,
    backgroundColor: Demo.backgroundColor,
    width: Demo.width,
    height: Demo.height,
    scene: Demo,
    parent: element_id,
  });
}

export default createGame;
