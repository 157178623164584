export interface Member {
  name: string;
  origin:
    | "Tirol"
    | "Wien"
    | "Kärnten"
    | "Steiermark"
    | "Oberösterreich"
    | "Unbekannt";
}

const members: Array<Member> = [
  {
    name: "Unbekannt",
    origin: "Unbekannt",
  },
  {
    name: "Sadri Fischer",
    origin: "Kärnten",
  },
  {
    name: "Patrick Waldner",
    origin: "Tirol",
  },
  {
    name: "Sebastian Baumann",
    origin: "Wien",
  },
  {
    name: "Jörg Huainigg",
    origin: "Kärnten",
  },
  {
    name: "Marco Huainigg",
    origin: "Kärnten",
  },
  {
    name: "Thomas Kraßnik",
    origin: "Kärnten",
  },
  {
    name: "Markus Herzer",
    origin: "Kärnten",
  },
  {
    name: "Rouven Amon",
    origin: "Wien",
  },
  {
    name: "Christian Bürger",
    origin: "Tirol",
  },
  {
    name: "Nikolaus Hölzl",
    origin: "Tirol",
  },
  {
    name: "Robert Stettner",
    origin: "Wien",
  },
  {
    name: "Martin Gaubitz",
    origin: "Wien",
  },
  {
    name: "Michael Kleber",
    origin: "Kärnten",
  },
  {
    name: "Michael Knoflach",
    origin: "Tirol",
  },
  {
    name: "Alexander Zeindlhofer",
    origin: "Oberösterreich",
  },
  {
    name: "Thomas Zimmer",
    origin: "Wien",
  },
  {
    name: "Harald Schellander",
    origin: "Kärnten",
  },
  {
    name: "Rainer Flassak",
    origin: "Wien",
  },
  {
    name: "Gerhard Ladengruber",
    origin: "Wien",
  },
  {
    name: "Daniel Mörlitz",
    origin: "Kärnten",
  },
  {
    name: "Alexander Eberharter",
    origin: "Tirol",
  },
];

export function getPlayercountOfRegion(
  region:
    | "Tirol"
    | "Wien"
    | "Kärnten"
    | "Steiermark"
    | "Oberösterreich"
    | "Unbekannt"
): number {
  let playerCount: number = 0;
  if (region === "Unbekannt") {
    return playerCount;
  }
  members.forEach((member: any) => {
    if (member.origin === region) {
      playerCount++;
    }
  });
  return playerCount;
}

export default members;
