import members, { Member, getPlayercountOfRegion } from "@/assets/js/members";

function findByName(name: string): Member {
  const member = members.find((member: Member) => {
    return member.name === name;
  });
  if (member) {
    return member;
  } else {
    throw new Error("Invalid Member Name: " + name);
  }
}

export interface RankingEntry {
  title: string;
  result: {
    first: Member;
    second: Member;
    third: Member;
  };
}

export const results: Array<RankingEntry> = [
  {
    title: "v18.1",
    result: {
      first: findByName("Alexander Eberharter"),
      second: findByName("Patrick Waldner"),
      third: findByName("Nikolaus Hölzl"),
    },
  },
  {
    title: "v18.2",
    result: {
      first: findByName("Robert Stettner"),
      second: findByName("Unbekannt"),
      third: findByName("Unbekannt"),
    },
  },
  {
    title: "v19.1",
    result: {
      first: findByName("Nikolaus Hölzl"),
      second: findByName("Markus Herzer"),
      third: findByName("Alexander Eberharter"),
    },
  },
  {
    title: "v19.2",
    result: {
      first: findByName("Alexander Eberharter"),
      second: findByName("Nikolaus Hölzl"),
      third: findByName("Robert Stettner"),
    },
  },
  {
    title: "v21.1",
    result: {
      first: findByName("Alexander Eberharter"),
      second: findByName("Patrick Waldner"),
      third: findByName("Christian Bürger"),
    },
  },
  {
    title: "v22.1",
    result: {
      first: findByName("Sebastian Baumann"),
      second: findByName("Alexander Zeindlhofer"),
      third: findByName("Rainer Flassak"),
    },
  },
  {
    title: "v22.2",
    result: {
      first: findByName("Michael Knoflach"),
      second: findByName("Markus Herzer"),
      third: findByName("Harald Schellander"),
    },
  },
  {
    title: "v23.1",
    result: {
      first: findByName("Alexander Eberharter"),
      second: findByName("Thomas Zimmer"),
      third: findByName("Harald Schellander"),
    },
  },
  {
    title: "v23.2",
    result: {
      first: findByName("Robert Stettner"),
      second: findByName("Nikolaus Hölzl"),
      third: findByName("Rouven Amon"),
    },
  },
];

export const totalIndividual = getTotalIndividual();
export const totalRegion = getTotalRegion();

function getTotalIndividual() {
  const totalIndividual: {
    name: string;
    origin:
      | "Tirol"
      | "Wien"
      | "Kärnten"
      | "Steiermark"
      | "Oberösterreich"
      | "Unbekannt";
    gold: number;
    silver: number;
    bronze: number;
  }[] = [];
  results.forEach((rankingEntry: RankingEntry) => {
    const totalEntryForFirst = totalIndividual.find(
      (totalIndividualEntry) =>
        totalIndividualEntry.name === rankingEntry.result.first.name
    );
    if (totalEntryForFirst) {
      totalEntryForFirst.gold = totalEntryForFirst.gold + 1;
    } else {
      totalIndividual.push({
        name: rankingEntry.result.first.name,
        origin: rankingEntry.result.first.origin,
        gold: 1,
        silver: 0,
        bronze: 0,
      });
    }

    const totalEntryForSecond = totalIndividual.find(
      (totalIndividualEntry) =>
        totalIndividualEntry.name === rankingEntry.result.second.name
    );
    if (totalEntryForSecond) {
      totalEntryForSecond.silver = totalEntryForSecond.silver + 1;
    } else {
      totalIndividual.push({
        name: rankingEntry.result.second.name,
        origin: rankingEntry.result.second.origin,
        gold: 0,
        silver: 1,
        bronze: 0,
      });
    }

    const totalEntryForThird = totalIndividual.find(
      (totalIndividualEntry) =>
        totalIndividualEntry.name === rankingEntry.result.third.name
    );
    if (totalEntryForThird) {
      totalEntryForThird.bronze = totalEntryForThird.bronze + 1;
    } else {
      totalIndividual.push({
        name: rankingEntry.result.third.name,
        origin: rankingEntry.result.third.origin,
        gold: 0,
        silver: 0,
        bronze: 1,
      });
    }
  });
  totalIndividual.sort((a, b) => {
    if (a.gold === b.gold) {
      if (a.silver === b.silver) {
        if (a.bronze === b.bronze) {
          return a.name > b.name ? 1 : -1;
        } else {
          return a.bronze > b.bronze ? -1 : 1;
        }
      } else {
        return a.silver > b.silver ? -1 : 1;
      }
    } else {
      return a.gold > b.gold ? -1 : 1;
    }
  });
  return totalIndividual;
}

function getTotalRegion() {
  const totalRegion: {
    name: string;
    playerCount: number;
    gold: number;
    silver: number;
    bronze: number;
  }[] = [];
  results.forEach((rankingEntry: RankingEntry) => {
    const totalEntryForFirst = totalRegion.find(
      (totalRegionEntry) =>
        totalRegionEntry.name === rankingEntry.result.first.origin
    );
    if (totalEntryForFirst) {
      totalEntryForFirst.gold = totalEntryForFirst.gold + 1;
    } else {
      totalRegion.push({
        name: rankingEntry.result.first.origin,
        playerCount: getPlayercountOfRegion(rankingEntry.result.first.origin),
        gold: 1,
        silver: 0,
        bronze: 0,
      });
    }

    const totalEntryForSecond = totalRegion.find(
      (totalRegionEntry) =>
        totalRegionEntry.name === rankingEntry.result.second.origin
    );
    if (totalEntryForSecond) {
      totalEntryForSecond.silver = totalEntryForSecond.silver + 1;
    } else {
      totalRegion.push({
        name: rankingEntry.result.second.origin,
        playerCount: getPlayercountOfRegion(rankingEntry.result.second.origin),
        gold: 0,
        silver: 1,
        bronze: 0,
      });
    }

    const totalEntryForThird = totalRegion.find(
      (totalRegionEntry) =>
        totalRegionEntry.name === rankingEntry.result.third.origin
    );
    if (totalEntryForThird) {
      totalEntryForThird.bronze = totalEntryForThird.bronze + 1;
    } else {
      totalRegion.push({
        name: rankingEntry.result.third.origin,
        playerCount: getPlayercountOfRegion(rankingEntry.result.third.origin),
        gold: 0,
        silver: 0,
        bronze: 1,
      });
    }
  });
  totalRegion.sort((a, b) => {
    if (a.gold === b.gold) {
      if (a.silver === b.silver) {
        if (a.bronze === b.bronze) {
          return a.name > b.name ? 1 : -1;
        } else {
          return a.bronze > b.bronze ? -1 : 1;
        }
      } else {
        return a.silver > b.silver ? -1 : 1;
      }
    } else {
      return a.gold > b.gold ? -1 : 1;
    }
  });
  return totalRegion;
}

export default {
  results,
  totalIndividual,
  totalRegion,
};
