
import { Component, Vue } from "vue-property-decorator";
@Component
export default class Setup extends Vue {
  private newPlayer = "";
  get tournamentState() {
    return this.$store.state.active;
  }
  get players() {
    return this.$store.state.data.players;
  }
  pushPlayer() {
    this.newPlayer = this.newPlayer.trim();
    if (this.newPlayer.length > 0) {
      if (this.players.includes(this.newPlayer)) {
        alert("Dieser Spieler wurde bereits hinzugefügt.");
      } else {
        this.$store.commit("pushPlayer", this.newPlayer);
      }
    }
    this.newPlayer = "";
  }
}
