
import { Component, Vue } from "vue-property-decorator";
@Component({
  props: {
    results: {
      required: true,
    },
  },
})
export default class RankingResults extends Vue {
  private results!: unknown;
}
