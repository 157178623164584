
import createGame from "@/assets/js/game/game";
import { Component, Vue } from "vue-property-decorator";
@Component
export default class Game extends Vue {
  private game: any = null;
  mounted() {
    this.game = createGame("robotron-game");
    console.log("game", this.game);
  }
}
