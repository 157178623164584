
import { Component, Vue } from "vue-property-decorator";
@Component({
  props: {
    medalScores: {
      type: Array,
      required: true,
    },
  },
})
export default class RankingMedals extends Vue {
  private readonly medalScores!: Array<{
    name: string;
    origin?: string;
    playerCount?: number;
    gold: number;
    silver: number;
    bronze: number;
  }>;

  get medalScoresExceptFirst(): Array<{
    name: string;
    origin?: string;
    playerCount?: number;
    gold: number;
    silver: number;
    bronze: number;
  }> {
    return this.medalScores.slice(1);
  }

  private goldWidth: number = 0;
  private silverWidth: number = 0;
  private bronzeWidth: number = 0;

  get goldStyle(): string {
    return this.goldWidth ? "width: " + this.goldWidth + "px;" : "";
  }
  get silverStyle(): string {
    return this.silverWidth ? "width: " + this.silverWidth + "px;" : "";
  }
  get bronzeStyle(): string {
    return this.bronzeWidth ? "width: " + this.bronzeWidth + "px;" : "";
  }

  getPathOfOrigin(origin: string) {
    switch (origin) {
      case "Tirol":
        return require("@/assets/images/regions/TRL@3x.png");
      case "Wien":
        return require("@/assets/images/regions/WN@3x.png");
      case "Kärnten":
        return require("@/assets/images/regions/KRNT@3x.png");
      case "Steiermark":
        return require("@/assets/images/regions/STMK@3x.png");
      case "Oberösterreich":
        return require("@/assets/images/regions/OOe@3x.png");
      case "Niederösterreich":
        return require("@/assets/images/regions/NOe@3x.png");
      case "Vorarlberg":
        return require("@/assets/images/regions/VRLB@3x.png");
      case "Salzburg":
        return require("@/assets/images/regions/SBG@3x.png");
      case "Burgenland":
        return require("@/assets/images/regions/BGL@3x.png");
      default:
        return require("@/assets/images/regions/UDF@3x.png");
    }
  }

  getTotal(medalScore: {
    name: string;
    origin?: string;
    playerCount?: number;
    gold: number;
    silver: number;
    bronze: number;
  }): number {
    return medalScore.gold + medalScore.silver + medalScore.bronze;
  }

  initResizeListeners() {
    const leadersGold = document.getElementById("leader-gold");
    const leadersSilver = document.getElementById("leader-silver");
    const leadersBronze = document.getElementById("leader-bronze");
    const leadersGoldObserver = new ResizeObserver((entries) => {
      this.goldWidth = entries[0].target.clientWidth;
    });
    const leadersSilverObserver = new ResizeObserver((entries) => {
      this.silverWidth = entries[0].target.clientWidth;
    });
    const leadersBronzeObserver = new ResizeObserver((entries) => {
      this.bronzeWidth = entries[0].target.clientWidth;
    });
    if (leadersGold) {
      leadersGoldObserver.observe(leadersGold);
    }
    if (leadersSilver) {
      leadersSilverObserver.observe(leadersSilver);
    }
    if (leadersBronze) {
      leadersBronzeObserver.observe(leadersBronze);
    }
  }

  fetchMedalSizes() {
    this.fetchGoldMedalSize();
    this.fetchSilverMedalSize();
    this.fetchBronzeMedalSize();
  }

  fetchGoldMedalSize() {
    this.goldWidth = document.getElementById("leader-gold")?.clientWidth || 0;
  }
  fetchSilverMedalSize() {
    this.silverWidth =
      document.getElementById("leader-silver")?.clientWidth || 0;
  }
  fetchBronzeMedalSize() {
    this.bronzeWidth =
      document.getElementById("leader-bronze")?.clientWidth || 0;
  }

  mounted() {
    this.fetchMedalSizes();
    this.initResizeListeners();
  }
}
