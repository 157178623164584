
import { Component, Vue } from "vue-property-decorator";
@Component
export default class TournamentFfa extends Vue {
  get currentGameIsRevealed(): boolean {
    return this.$store.state.data.ffa.states.revealed;
  }
  get games(): Array<{ name: string; image: unknown }> {
    return this.$store?.state?.data?.ffa?.games ?? [];
  }
  get totalGames(): number {
    return this.games.length;
  }
  get currentGame() {
    return this.$store.state.data.ffa.active;
  }
  get currentGameIndex(): number {
    return this.games.findIndex((game: { name: string; image: unknown }) => {
      return game.name === this.currentGame?.name;
    });
  }
  get currentGameIsDone(): boolean {
    return this.$store.state.data.ffa.states.done;
  }
  get currentGameIsLastgame(): boolean {
    return this.currentGameIndex === this.games.length - 1;
  }

  revealCurrentGame() {
    this.$store.commit("setCurrentGameIsRevealed", true);
  }
  finishGame() {
    this.$store.commit("setCurrentGameIsDone", true);
  }
  goToNextGame() {
    this.$store.commit("setCurrentGameIsRevealed", false);
    this.$store.commit("setCurrentGameIsDone", false);
    this.$store.commit("setCurrentGame", this.getNextGame());
  }
  getNextGame() {
    if (this.currentGameIsLastgame) {
      alert("This should not be possible. Last game was already played.");
      return;
    }
    if (this.currentGameIndex === -1) {
      return this.games[0];
    } else {
      return this.games[this.currentGameIndex + 1];
    }
  }
  goToFinal() {
    this.$store.commit("setTournamentState", "final");
  }
  created() {
    if (!this.currentGame) {
      this.goToNextGame();
    }
  }
}
