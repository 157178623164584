
import { Component, Vue } from "vue-property-decorator";
import Game from "@/components/game/index.vue";
@Component({
  components: { Game },
})
export default class ViewGame extends Vue {
  get tournamentIsActive() {
    return this.$store.state.active;
  }
}
